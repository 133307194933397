<script setup lang="ts">
import localforage from 'localforage';

// Composables
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const { $crisp } = useNuxtApp();

// Data
const notificationModalStatus = ref(false);
const mounted = ref(false);
const isOpen = ref(true);

// Computed
const path = computed(() => route.path);

const isDemoDomain = computed(() => {
  return window.location.hostname === 'demo.nrjx.tech';
});

const user = computed(() => {
  return userStore().getCurrentUser;
});

const currentClient = computed(() => {
  return globalStore().getSelectedClient;
});

const currentSite = computed(() => {
  if (globalStore().getSelectedSite.length > 1) {
    return 0;
  }
  return globalStore().getSelectedSite[0];
});

const getNumberOfNotifications = computed(() => {
  return notificationStore().getNotifications.length;
});

const getClients = computed(() => {
  const clients = clientStore().getClients;
  return clients.map((client) => {
    return {
      id: client.id,
      label: client.name,
    };
  });
});

const getSites = computed(() => {
  const items = siteStore().getSites.map((sites) => {
    return {
      id: sites.id,
      label: sites.name,
    };
  });

  items.unshift({
    id: 0,
    label: t('global.all_sites'),
  });

  return items;
});

// Methods
const selectClient = (id: number) => {
  globalStore().setSelectedClient(id);
  globalStore().initApp();
  router.push('/');
};

const selectSite = (id: number) => {
  globalStore().setSelectedSite(id);
  globalStore().initApp();
  router.push('/');
};

const setSideBarStatus = async () => {
  isOpen.value = !isOpen.value;
  await localforage.setItem('nrjx-sidebar-status', isOpen.value);
};

// Lifecycle
onMounted(async () => {
  const status = await localforage.getItem<boolean>('nrjx-sidebar-status');
  isOpen.value = status ?? true;
  setTimeout(() => {
    mounted.value = true;
  }, 100);
});

// Crisp
$crisp.chat.close();
$crisp.chat.hide();
const isCrispChatOpen = ref(false);

function toggleCrispChat() {
  if (isCrispChatOpen.value) {
    $crisp.chat.close();
    $crisp.chat.hide();
    isCrispChatOpen.value = false;
  } else {
    $crisp.chat.open();
    $crisp.chat.show();
    isCrispChatOpen.value = true;
  }
}

$crisp.chat.onChatClosed(() => {
  $crisp.chat.hide();
  isCrispChatOpen.value = false;
});
</script>
<template>
  <nav
    v-if="user"
    class="h-screen overflow-y-auto bg-white border-r border-gray-200 px-4 pt-4 pb-2 flex flex-col justify-between"
    :class="[mounted ? 'transition-all duration-200' : 'transition-none', isOpen ? 'w-[220px]' : 'w-[64px]']"
  >
    <div
      class="fixed top-[24px] h-6 w-6 border rounded-full border-gray-200 bg-white flex items-center justify-center"
      :class="[isOpen ? 'left-[208px]' : 'left-[52px]']"
      @click="setSideBarStatus"
    >
      <ui-icon
        name="ChevronFirst"
        :class="[isOpen ? 'rotate-0 ' : 'rotate-180']"
        class="h-4 text-gray-600 cursor-pointer transition-all duration-200"
      />
    </div>
    <div>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <img v-if="isOpen" src="/assets/nrjx-logo.svg" alt="Nrjx logo" class="h-11" />
          <div v-else class="flex items-center justify-center h-10 cursor-pointer">
            <img src="/assets/nrjx-mini-logo.svg" alt="Nrjx logo" class="h-[31px]" />
          </div>
        </div>
      </div>

      <div
        v-if="getSites.length > 2 || user.role === 'admin_app'"
        class="flex flex-col items-center border-b border-gray-200 pb-4 border-t pt-4"
      >
        <!-- Select client -->
        <nrjx-input-select
          v-if="user.role === 'admin_app'"
          :model-value="currentClient"
          name="client"
          size="sm"
          searchable
          :search-placeholder="$t('global.search_a_client')"
          :search-key="['label']"
          :content="
            isOpen ? { direction: 'bottom', align: 'start', sideOffset: 4 } : { direction: 'right', align: 'start', sideOffset: 22 }
          "
          :items="getClients"
          :fit-to-select="isOpen"
          class="w-full"
          :class="getSites.length > 2 ? 'mb-2' : ''"
          @update:model-value="selectClient"
        >
          <template #trigger="{ open }">
            <nrjx-tooltip v-if="!isOpen" :message="t('global.clients')" side="right" :side-offset="20">
              <div
                class="h-8 w-8 flex items-center justify-center hover:bg-gray-100 rounded-md cursor-pointer"
                :class="open ? 'border-primary-500 ring-primary-100 ring-[2px] border-[1px]' : ''"
              >
                <ui-icon name="UserSearch" class="h-5" />
              </div>
            </nrjx-tooltip>
          </template>
        </nrjx-input-select>

        <!-- Select site -->
        <nrjx-input-select
          v-if="getSites.length > 2"
          :model-value="currentSite"
          name="site"
          size="sm"
          searchable
          :search-placeholder="$t('global.search_a_site')"
          :search-key="['label']"
          :content="
            isOpen ? { direction: 'bottom', align: 'start', sideOffset: 4 } : { direction: 'right', align: 'start', sideOffset: 22 }
          "
          :items="getSites"
          :fit-to-select="isOpen"
          class="w-full"
          @update:model-value="selectSite"
        >
          <template #trigger="{ open }">
            <nrjx-tooltip v-if="!isOpen" :message="t('global.sites')" side="right" :side-offset="20">
              <div
                class="h-8 w-8 flex items-center justify-center hover:bg-gray-100 rounded-md cursor-pointer"
                :class="open ? 'border-primary-500 ring-primary-100 ring-[2px] border-[1px]' : ''"
              >
                <ui-icon name="Factory" class="h-[18px]" />
              </div>
            </nrjx-tooltip>
          </template>
        </nrjx-input-select>
      </div>

      <div class="w-full flex flex-col items-start justify-start py-4 space-y-2">
        <!-- Overview -->
        <nrjx-sidebar-item
          :side-bar-status="isOpen"
          to="/"
          icon="LayoutDashboard"
          :condition="path === '/'"
          :label="$t('global.overview')"
          :path="path"
        />

        <!-- Dashboard -->
        <nrjx-sidebar-item
          v-if="user.role === 'admin_app' && isDemoDomain"
          :side-bar-status="isOpen"
          to="/new-dashboard"
          icon="Presentation"
          :condition="path === '/new-dashboard'"
          :label="$t('global.dashboard')"
          :path="path"
        />

        <!-- Explore -->
        <nrjx-sidebar-item
          v-if="currentSite !== 0"
          :side-bar-status="isOpen"
          icon="LineChart"
          to="/explore/chronological"
          :condition="path.includes('/explore')"
          :label="$t('navigation.explore')"
        />
        <nrjx-sidebar-item
          v-else
          :side-bar-status="isOpen"
          to="/explore/global"
          icon="LineChart"
          :condition="path === '/explore/global'"
          :label="$t('navigation.explore')"
          :path="path"
        />

        <!-- Standard -->
        <nrjx-sidebar-item
          v-if="currentSite !== 0"
          :side-bar-status="isOpen"
          icon="BarChartBig"
          to="/standard"
          :condition="path.includes('/standard')"
          :label="$t('navigation.standard')"
        />

        <!-- Products -->
        <nrjx-sidebar-item
          :side-bar-status="isOpen"
          to="/products"
          icon="ScanBarcode"
          :condition="(route.name as string).includes('product-')"
          :label="$t('nav.products')"
        />

        <!-- Incidents -->
        <nrjx-sidebar-item
          :side-bar-status="isOpen"
          to="/incidents"
          icon="TriangleAlert"
          :condition="path.includes('/incident')"
          :label="$t('global.incidents')"
          :path="path"
        />

        <!-- Action plan -->
        <nrjx-sidebar-item
          :side-bar-status="isOpen"
          to="/actions"
          icon="CircleCheckBig"
          :condition="path.includes('/action')"
          :label="$t('navigation.action_plan')"
          :path="path"
        />
      </div>
    </div>

    <div class="flex flex-col items-start justify-start space-y-2">
      <!-- Notifications -->
      <div
        v-if="isOpen"
        class="group cursor-pointer flex items-center gap-4 px-2 py-2 my-auto h-fit rounded-lg truncate w-full text-sm"
        :class="[notificationModalStatus ? 'text-primary-500 bg-gray-100' : 'hover:bg-gray-100 text-gray-600 cursor-pointer']"
        @click="notificationModalStatus = true"
      >
        <div class="relative">
          <ui-icon
            :class="[notificationModalStatus ? 'stroke-primary' : 'stroke-gray-600']"
            class="h-[18px]"
            :stroke-width="2"
            name="Bell"
          />
          <div
            v-if="getNumberOfNotifications > 0"
            class="h-4 w-4 bg-primary text-[10px] text-white rounded-full absolute -top-2 -right-1.5 flex items-center justify-center after:absolute after:content-[''] after:w-3 after:h-3 after:bg-primary after:rounded-full after:animate-ping after:-z-10"
            :class="{ 'after:hidden': getNumberOfNotifications === 0 }"
          >
            {{ getNumberOfNotifications }}
          </div>
        </div>
        <span class="text-sm">{{ t('global.notifications') }}</span>
      </div>

      <nrjx-tooltip v-else :message="t('global.notifications')" side="right" :side-offset="20">
        <div
          class="h-8 w-8 group flex items-center rounded-lg truncate justify-center cursor-pointer"
          :class="[notificationModalStatus ? 'text-primary-500 bg-gray-100' : 'hover:bg-gray-100 text-gray-600 cursor-pointer']"
          @click="notificationModalStatus = true"
        >
          <ui-icon
            class="h-[18px]"
            :class="[notificationModalStatus ? 'stroke-primary-500' : 'stroke-gray-600']"
            :stroke-width="2"
            name="Bell"
          />
        </div>
      </nrjx-tooltip>

      <!-- Configuration -->
      <nrjx-sidebar-item
        :side-bar-status="isOpen"
        to="/configuration/alerts"
        icon="Settings"
        :condition="path.includes('/configuration')"
        :label="$t('global.configurations')"
        :path="path"
      />

      <!-- Help -->
      <button
        v-if="isOpen"
        class="group cursor-pointer flex items-center gap-4 px-2 py-2 my-auto h-fit rounded-lg truncate w-full text-sm"
        :class="[isCrispChatOpen ? 'text-primary-500 bg-gray-100' : 'hover:bg-gray-100 text-gray-600 cursor-pointer']"
        @click="toggleCrispChat"
      >
        <ui-icon :class="[isCrispChatOpen ? 'stroke-primary' : 'stroke-gray-600']" class="h-[18px]" :stroke-width="2" name="CircleHelp" />
        <span class="text-sm">
          {{ t('global.help') }}
        </span>
      </button>
      <nrjx-tooltip v-else :message="t('global.help')" side="right" :side-offset="20">
        <div
          class="h-8 w-8 group flex items-center rounded-lg truncate justify-center cursor-pointer"
          :class="[isCrispChatOpen ? 'text-primary-500 bg-gray-100' : 'hover:bg-gray-100 text-gray-600 cursor-pointer']"
          @click="toggleCrispChat"
        >
          <ui-icon
            class="h-[18px]"
            :class="[isCrispChatOpen ? 'stroke-primary-500' : 'stroke-gray-600']"
            :stroke-width="2"
            name="CircleHelp"
          />
        </div>
      </nrjx-tooltip>

      <!-- Admin -->
      <nrjx-sidebar-item
        v-if="user.role === 'admin_app'"
        :side-bar-status="isOpen"
        to="/admin"
        icon="Lock"
        :condition="path.includes('/admin')"
        :label="$t('global.admin')"
        :path="path"
      />

      <!-- Component library -->
      <nrjx-sidebar-item
        v-if="user.role === 'admin_app'"
        :side-bar-status="isOpen"
        to="/component-library/button"
        icon="Code"
        :condition="path.includes('/component-library')"
        :label="$t('global.components')"
        :path="path"
      />

      <div class="flex justify-between border-t border-gray-200 pt-2 mt-2 w-full">
        <nrjx-sidebar-account :side-bar-status="isOpen" />
      </div>
    </div>
  </nav>

  <notification-modal v-model="notificationModalStatus" />
</template>
