<script setup lang="ts">
// Composables
const { t, locale } = useI18n();
const router = useRouter();

// Store
const { getCurrentUser } = storeToRefs(userStore());

// Props
defineProps<{
  sideBarStatus: boolean;
}>();

// Data
const condition = ref(false);
const menu = ref(false);
const langMenu = ref(false);

// Computed
const langList = computed(() => {
  return [
    {
      label: t('languages.english'),
      value: 'en',
      isCurrent: locale.value === 'en',
    },
    {
      label: t('languages.french'),
      value: 'fr',
      isCurrent: locale.value === 'fr',
    },
  ];
});

// Methods
const goTo = (to: string) => {
  menu.value = false;
  navigateTo(to);
};

const changeLanguage = (lang: string) => {
  locale.value = lang;
  menu.value = false;
  useHead({
    htmlAttrs: {
      lang: locale.value,
    },
  });
};

const logout = async () => {
  menu.value = false;
  await firebaseStore().logout();
  router.push('/auth/login');
};
</script>
<template>
  <popover-root v-model:open="menu" class="w-full">
    <popover-trigger class="w-full">
      <div
        v-if="sideBarStatus"
        class="group flex items-center rounded-lg truncate w-full px-3 py-2 gap-4"
        :class="[condition ? 'text-primary-500 bg-gray-100' : 'hover:bg-gray-100 text-gray-600 cursor-pointer']"
      >
        <AvatarRoot
          class="border-stone-700 bg-stone-800 inline-flex h-[24px] w-[24px] select-none items-center justify-center overflow-hidden rounded-full align-middle"
        >
          <AvatarImage
            v-if="getCurrentUser.picture_url"
            class="h-full w-full rounded-[inherit] object-cover"
            :src="getCurrentUser.picture_url"
            :alt="getCurrentUser.first_name + ' ' + getCurrentUser.last_name"
          />
          <AvatarFallback class="text-gray-200 leading-1 flex h-full w-full items-center justify-center bg-gray-600 text-xs font-medium">
            {{ getCurrentUser.first_name.charAt(0) + getCurrentUser.last_name.charAt(0) }}
          </AvatarFallback>
        </AvatarRoot>
        <span class="text-sm"> {{ getCurrentUser.first_name }} {{ getCurrentUser.last_name }} </span>
      </div>
      <div
        v-else
        class="h-8 w-8 group flex items-center rounded-lg truncate justify-center"
        :class="[condition ? 'text-primary-500 bg-gray-100' : 'hover:bg-gray-100 text-gray-600 cursor-pointer']"
      >
        <AvatarRoot
          class="border-stone-700 bg-stone-800 inline-flex h-[24px] w-[24px] select-none items-center justify-center overflow-hidden rounded-full align-middle"
        >
          <AvatarImage
            v-if="getCurrentUser.picture_url"
            class="h-[24px] w-[24px] rounded-[inherit] object-cover"
            :src="getCurrentUser.picture_url"
            :alt="getCurrentUser.first_name + ' ' + getCurrentUser.last_name"
          />
          <AvatarFallback class="text-gray-200 leading-1 flex h-full w-full items-center justify-center bg-gray-600 text-xs font-medium">
            {{ getCurrentUser.first_name.charAt(0) + getCurrentUser.last_name.charAt(0) }}
          </AvatarFallback>
        </AvatarRoot>
      </div>
    </popover-trigger>

    <!-- Menu -->
    <popover-portal>
      <popover-content
        position-strategy="fixed"
        side="right"
        align="end"
        :side-offset="22"
        class="bg-white z-50 min-h-[100%] shadow border rounded-md border-gray-200 p-1 space-y-1"
      >
        <!-- Languages -->
        <popover-root v-model:open="langMenu" class="w-full">
          <popover-trigger class="w-full">
            <div
              class="select-none w-full cursor-pointer flex items-center justify-between px-2 py-1.5 text-sm rounded-md"
              :class="[false ? 'text-primary-500 bg-gray-100' : 'hover:bg-gray-100 text-gray-600 cursor-pointer']"
            >
              {{ $t('global.languages') }}
            </div>
          </popover-trigger>
          <popover-content
            position-strategy="fixed"
            side="right"
            align="end"
            :side-offset="10"
            class="bg-white z-50 min-h-[100%] shadow border rounded-md border-gray-200 p-1 space-y-1"
            @click="langMenu = false"
          >
            <div
              v-for="lang in langList"
              :key="lang.value"
              class="select-none cursor-pointer flex items-center justify-between px-2 py-1.5 text-sm rounded-md"
              :class="[lang.isCurrent ? 'text-primary-500 bg-gray-100' : 'hover:bg-gray-100 text-gray-600 cursor-pointer']"
              @click="changeLanguage(lang.value)"
            >
              {{ lang.label }}
            </div>
          </popover-content>
        </popover-root>

        <!-- Profile -->
        <div
          class="select-none cursor-pointer flex items-center justify-between px-2 py-1.5 text-sm rounded-md"
          :class="[$route.path === '/profile' ? 'text-primary-500 bg-gray-100' : 'hover:bg-gray-100 text-gray-600 cursor-pointer']"
          @click="goTo('/profile')"
        >
          {{ $t('profile.my_profile') }}
        </div>

        <!-- Logout -->
        <div
          class="select-none flex items-center px-2 py-2 text-sm rounded-md hover:bg-gray-100 text-red-500 cursor-pointer"
          :class="[false ? 'text-primary-500 bg-gray-100' : '']"
          @click="logout"
        >
          {{ $t('global.logout') }}
        </div>
      </popover-content>
    </popover-portal>
  </popover-root>
</template>
